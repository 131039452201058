import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  apiUrl = `${environment.apiUrl}`;
  constructor(private httpClient: HttpClient) {}

  public getStatisticsData(): Observable<any> {
    const url = this.apiUrl + 'statistics';
    return this.httpClient.get(url);
  }

  public getStatisticsOmimEntriesData(paginator): Observable<any> {
    const url = this.apiUrl + 'omimentries';
    return this.httpClient.post(url, paginator);
  }

  public runScrapperForSpecificEntryData(mimNumber): Observable<any> {
    const url = this.apiUrl + `extractor/${mimNumber}`;
    return this.httpClient.get(url);
  }

  runScrapperForSpecificClinicalSynopsis(mimNumber): Observable<any> {
    const url = this.apiUrl + `ExtractClinicalSynopsis/${mimNumber}`;
    return this.httpClient.get(url);
  }

  runScrapperGeneralData(dataType: string): Observable<any> {
    const url = this.apiUrl + `Extractor/${dataType}`;
    return this.httpClient.get(url);
  }
}
