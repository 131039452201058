import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class DrugsService {
  apiUrl = `${environment.apiBaseUrl}`;
  apiFdaUrl = `${environment.apiFdaUrl}`;
  constructor(private httpClient: HttpClient) {}

  public getDrugs(data): Observable<any> {
    if (!data.pageSize) {
      data['PageSize'] = 20;
    }

    const url = this.apiUrl + 'fda/NovelDrugs';
    return this.httpClient.post(url, data);
  }

  public getDrugsDetail(id): Observable<any> {
    const url = this.apiFdaUrl + 'Details/' + id;
    return this.httpClient.get(url);
  }
}
