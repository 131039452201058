import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-icd-banner',
  templateUrl: './icd-banner.component.html',
  styleUrls: ['./icd-banner.component.css']
})
export class IcdBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
