import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IcdComponent } from './icd.component';
import { IcdRoutingModule } from './icd-routing.module';
import { IcdBannerComponent } from './icd-banner/icd-banner.component';
import { IcdContentComponent } from './icd-content/icd-content.component';
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "../shared/shared.module";


@NgModule({
  declarations: [
     IcdComponent, IcdBannerComponent, IcdContentComponent
  ],
  imports: [
    CommonModule,
    IcdRoutingModule,
    SharedModule,
    TranslateModule,
  ]
})
export class IcdModule { }

