<section class="banner_area">
  <div class="banner_inner d-flex align-items-center">
    <div
      class="overlay bg-parallax"
      data-stellar-ratio="0.9"
      data-stellar-vertical-offset="0"
      data-background=""
    ></div>
    <div class="container">
      <div class="banner_content text-center">
        <h2>ICD</h2>
        <div class="page_link">
          <a routerLink="/">{{ "Home" | translate }}</a>
          <a href="javascript:void(0)">{{ "ICD" | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</section>
